export default [
  {
    title: "Emilie S.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Qualité, Réactivité",
    description: `Suite à une chaudière TRÈS capricieuse nécessitant plusieurs changements de pièce, j'ai à nouveau de l'eau chaude.
    Merci à A****** et L*** pour leur professionnalisme et leur bonne humeur.
    Parfois avoir de la patience, ça vaut vraiment le coup.
    Je recommande chaudement cette entreprise, elle est très sérieuse.
    `,
  },
  {
    title: "Jason D.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Qualité, Rapport qualité-prix",
    description: `Changement de chauffe eau effectué aujourd'hui par l'équipe de technicien Selfcity. L'équipe est arrivé dans la plage horraire annoncé. De plus, le personnel était sympatique et efficace. Travail très satisfaisant.`,
  },
  {
    title: "Dominique B.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Professionnalisme",
    description: `Très bon service. Respect des horaires et professionnalisme. A****** a passé plus de 2 h à résoudre  la panne du chauffe-eau bien qu’il soit intervenu après 18h. Il a le soucis de satisfaire le client et en plus il est Très sympathique. A recommander`,
  },
  {
    title: "Ophélie C.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Qualité, Rapport qualité-prix, Réactivité",
    description: `Tout simplement parfait, L***, l'intervenant de la société, a été soucieux de bien faire .. Donc rien à dire, à part que nous sommes très satisfait, merci pour ce service de qualité !
    `,
  },
  {
    title: "Zinedine O.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Qualité, Rapport qualité-prix, Réactivité",
    description: `A****** a été super, ainsi que son collègue T******, l'opération a été faites trés rapidement et sans complications, je recommande fortement`,
  },
  {
    title: "Alexia H.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Efficacité, Réactivité, Professionnalisme",
    description: `Société très réactive, j'ai eu un RDV pour l'entretien de ma chaudière dans la semaine et la personne au téléphone a été très agréable. 
    L'intervenant à été très professionnel, efficace et agréable. 
    Leurs prix sont tout à fait corrects.
    Rien à redire, je recommande vivement.
    `,
  },
  {
    title: "Jonathan D",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Qualité, Réactivité",
    description: `Entreprise très réactive et conciliante ! 
    Le technicien (le même pour le devis et pour la réparation) était très professionnel et compétent, je recommande fortement !
    `,
  },
  {
    title: "Fatima S.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Qualité, Professionnalisme",
    description: `Excellent travail, intervention de 3 plombiers avant et les 3 ont fait un mauvais diagnostic.
    Très bonne expérience avec cette structure , personnel très compétent et agréable , de l’accueil téléphonique au standard jusqu’au technicien.
    `,
  },
  {
    title: "Tony M.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Qualité, Rapport qualité-prix, Réactivité",
    description: `Très satisfait de cette intervention. A****** a su gérer avec beaucoup de professionnalisme une difficulté imprévue dans le remplacement du système d'évacuation. Et en plus il est très sympathique ! C'est rassurant de constater qu'il existe encore des gens qui aiment leur métier et qui le font bien. Les tarifs sont particulièrement raisonnables. Bref, je recommande fortement cette société. Et je vous souhaite toute la réussite que vous méritez. T. M.`,
  },
  {
    title: "Claire D.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Rapport qualité-prix, Réactivité",
    description: `L*** a été très pro, très clair dans ses explications du problème... qui a été réglé rapidement.
    Je n'hésiterai pas à refaire appel à SelfCity si besoin.
    `,
  },
];
