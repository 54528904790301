import React, { useState, useContext } from 'react'
import { AppContext } from '../../../context'

import GAEventHelper from '../../../utils/GAEventHelper'
import ModalHelper from '../../../utils/ModalHelper'

import CONSTANTS from '../../../utils/constants'

import pressMedias from '../../../assets/press'
import reviews from '../../../assets/ratings/index-v2'
import ReviewCard from '../../Slider/ReviewCard'
import { openNewJotformTab } from '../../../utils/jotformLink'

import View from './View'

const App = () => {
	const [state] = useContext(AppContext)
	const { phoneNumber } = state

	const [showModal, setShowModal] = useState(false)
	const [currentModalLabel, setCurrentModalLabel] = useState(null)

	const handleOpenContactFormModal = (e, metadata = {}) => {
		e.preventDefault()

		const label = ModalHelper.triggerGAClickEvent({
			category: CONSTANTS.GOOGLE_ANALYTICS.CATEGORIES.DEMANDE_DEVIS,
			action: CONSTANTS.GOOGLE_ANALYTICS.ACTIONS.OPEN,
			event: e,
			metadata,
			options: {
				window,
			},
		})

		openNewJotformTab('selfcity_accueil')

		// setShowModal(true)
		// setCurrentModalLabel(label)
	}

	const handleCloseContactFormModal = () => {
		setShowModal(false)
		setCurrentModalLabel(null)
	}

	const reviewsCards = reviews.map((review, i) => {
		return <ReviewCard key={i} review={review} />
	})

	const pressAnchors = pressMedias.map((press) => {
		const { id, href, src, alt } = press
		return (
			<a key={id} href={href} target="no_blank">
				<img className="press-logo" src={src} alt={alt} />
			</a>
		)
	})

	return (
		<View
			phoneNumber={phoneNumber}
			reviewsCards={reviewsCards}
			pressAnchors={pressAnchors}
			showModal={showModal}
			currentModalLabel={currentModalLabel}
			handleOpenContactFormModal={handleOpenContactFormModal}
			handleCloseContactFormModal={handleCloseContactFormModal}
		/>
	)
}
export default App
