import React from "react";

import Map from "../../elements/Map";
import FormButton from "../../elements/FormButton";
import Slider from "../../Slider";
import ContactFormModal from "../../elements/ContactFormModal";
import RatingsAnchor from "../../elements/RatingsAnchor";

import "../../../styles/index-2020-04.css";

import { forfaitsList } from "../../../utils/constants";

export default ({
  reviewsCards,
  pressAnchors,
  phoneNumber,
  currentModalLabel,
  showModal,
  handleOpenContactFormModal,
  handleCloseContactFormModal,
}) => {
  return (
    <main className="style-2020-04 page-home">
      {showModal ? (
        <ContactFormModal
          onClose={handleCloseContactFormModal}
          title="Demande de RDV"
          label={currentModalLabel}
        />
      ) : null}
      <section className="home-section  section-intro">
        <div className="flex flex-align-center flex-justify-space-between">
          <div className="section-content flex-55">
            <h1 className="section-title">
              Le dépannage en plomberie, renouvelé.
            </h1>
            <h2 className="section-title">
              <span className="dark-green">Qualité</span>.
              <span className="dark-green"> Réactivité</span>.
              <span className="dark-green"> Transparence</span>.
            </h2>
            <p className="section-body">
              Selfcity renouvelle le dépannage en plomberie pour un service
              fiable et réactif. Retrouvez rapidement le bonheur d’être chez
              vous à un prix abordable.
            </p>
            <div className="awards flex flex-align-end">
              <span className="">
                Entreprise <br className="hide-big" /> récompensée :
              </span>
              <span>
                <img
                  src="/img/partners/logo_ihg.png"
                  alt="Initiative Haute-Garonne"
                  width="95px"
                  className="hide-sm"
                />
                <img
                  src="/img/partners/logo_reseau_entreprendre_x80.png"
                  alt="Réseau Entreprendre"
                  height="55px"
                />
                <img
                  src="/img/partners/logo_agipi_finaliste.png"
                  alt="AGIPI"
                  height="55px"
                  className="awards-logo-agipi hide-big"
                />
              </span>
            </div>
          </div>
          <div className="section-illustration">
            <img
              src="/img/index/home_image_stars.png"
              alt="Maison avec plombier"
              width="350px"
              className="hide-sm"
            />
            <img
              src="/img/index/home_image_2.png"
              alt="Maison avec plombier"
              width="350px"
              className="hide-big"
            />
          </div>
        </div>
        <div className=" block-form-button">
          <FormButton
            text="Demander un RDV"
            label="intro"
            onClick={handleOpenContactFormModal}
          />
        </div>
      </section>

      <section className="home-section flex section-best-service">
        <div className="section-illustration flex-55">
          <img
            id="team-img"
            className="hide-big"
            src="/img/team2_768x.jpg"
            alt="Equipe de Selfcity"
            width="400px"
          />
        </div>
        <div className="section-content bg-grey-1 ">
          <h2 className="section-title">
            Nous employons
            <span className="dark-green bold"> les meilleurs </span>
            plombiers-chauffagistes
          </h2>
          <p>
            Nous recrutons les meilleurs et créons les bonnes conditions de
            travail afin qu’ils vous offrent
            <span className="bold"> la meilleure prestation </span>
            possible.
          </p>
          <p>
            Grâce à notre organisation innovante, vous bénéficiez d’une qualité
            de service premium à
            <span className="bold"> des tarifs plus bas </span>
            que le marché.
          </p>
          <div className="block-form-button">
            <FormButton
              text="Demander un RDV"
              label="best-service"
              onClick={handleOpenContactFormModal}
              classes={"bg-grey-1"}
            />
          </div>
        </div>
      </section>

      <section className="home-section center section-tagline">
        <h2 className="section-title hide-sm">
          Si vous avez besoin d’un plombier, c’est que votre journée a mal
          commencé… <br />
          <span className="dark-green bold">
            Notre mission, c’est qu’elle se termine bien.
          </span>
        </h2>
        <h2 className="section-title hide-big dark-green bold">
          La satisfaction client est <br /> notre obsession
        </h2>
        <div>
          <Slider cards={reviewsCards} />
          <div className="m-t-1 hide-sm">
            <RatingsAnchor />
          </div>
        </div>
        <div className="block-form-button">
          <FormButton
            text="Demander un RDV"
            label="tagline"
            onClick={handleOpenContactFormModal}
          />
        </div>
      </section>

      <section className="home-section section-press">
        <div className="flex flex-column flex-center">
          <h3 className="section-title">Ils parlent de nous</h3>
          <div className="press-logos flex flex-justify-space-evenly">
            {pressAnchors}
          </div>
        </div>
      </section>

      <section className="home-section section-pricing">
        <h3 className="section-title center hide-sm">
          Que ce soit sur devis ou en dépannage d’urgence, les tarifs de
          Selfcity sont transparents <br />
          et
          <span className="green"> toujours connus d’avance</span>.
        </h3>
        <h3 className="section-title hide-big">
          Nos tarifs sont transparents
          <br /> et
          <span className="green"> toujours connus d’avance</span>.
        </h3>

        <div className="section-body flex flex-justify-space-evenly ">
          <div className="panel-pricing flex-45">
            <h5 className="center panel-title">Interventions forfaitisées</h5>
            <p className="hide-sm">
              Pour les dépannages en plomberie, chauffage et clim à Toulouse,
              nous appliquons les taris suivants :
            </p>
            <ul className="">
              {forfaitsList.map(({ name, price }, idx) => (
                <li key={idx}>
                  <span>{`- ${name}`}</span>
                  <span className="mar-l-2">{price}€&nbsp;TTC</span>
                </li>
              ))}
            </ul>
          </div>

          <hr className="vertical-line green hide-sm" />

          <div className="panel-pricing panel-pricing-quote flex-45">
            <h5 className="center panel-title">Travaux sur devis</h5>
            <p>
              Après un RDV gratuit avec l'un de nos plombiers, nous vous faisons
              parvenir un devis dont le montant, (hors achat de matériel), est
              calculé comme suit :
            </p>
            <ul className="">
              <li>
                <span>- Forfait de déplacement -------</span>
                <span>30 € TTC</span>
              </li>
              <li>
                <span>- 1 heure de main d'oeuvre -----</span>
                <span>60 € TTC</span>
              </li>
              <li>
                <span>- Forfait de petite fourniture ----</span>
                <span>5 € TTC</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="block-form-button center">
          <FormButton
            text="Demander un RDV"
            label="pricing"
            onClick={handleOpenContactFormModal}
          />
        </div>
      </section>

      <section className="home-section  flex flex-center section-green">
        <div className="bg-green white section-content">
          <h3 className="section-title">
            Un logement sain dans
            <br /> une ville saine
          </h3>
          <p>
            Pour contribuer à une ville dépolluée, nos plombiers circulent en
            véhicules électriques.
          </p>
          <p>
            Pour aller au bout de notre démarche, nous rechargeons nos véhicules
            par de l’électricité verte, issue des énergies renouvelables.
          </p>
          <div className="block-form-button">
            <FormButton
              text="Demander un RDV"
              label="green"
              onClick={handleOpenContactFormModal}
              reverse={true}
            />
          </div>
        </div>
        <div className="section-illustration flex-55">
          <img
            className="hide-big"
            src="/img/index/green-city.jpg"
            alt="Ville saine"
            width="400px"
          />
        </div>
      </section>

      <section className="home-section center section-contact">
        <h2 className="section-title">
          Un souci de plomberie <br className="hide-big" /> ou de chauffage ?
        </h2>

        <div className="section-body">
          <p className="m-0">Appelez-nous au :</p>
          <p className="m-0 text-2xl font-droid section-contact__phone-number">
            {phoneNumber.default}
          </p>
        </div>
        <p className="section-body-2">Ou faites votre demande en ligne :</p>
        <div className="block-form-button">
          <FormButton
            text="Demander un RDV"
            label="contact"
            onClick={handleOpenContactFormModal}
          />
        </div>
      </section>

      <section className="home-section flex flex-justify-center section-contact-infos">
        <div className=" section-map hide-sm">
          <Map />
        </div>
        <div className="section-content">
          <h3 className="section-title">Selfcity Inc.</h3>
          <div className="section-body">
            <p>
              2 avenue de Lombez <br />
              31300 TOULOUSE
            </p>
            <p>
              {phoneNumber.default}
              <br />
              contact@selfcity.fr
            </p>
            <p className="hide-sm">
              SAS au capital de 35.000 €
              <br /> RCS de Toulouse : <br />
              84860177900012
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};
