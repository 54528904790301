import React from "react";
import Index from "../components/pages/index-2020-04";
import Layout from "../layouts/Layout";
import CONSTANTS from "../utils/constants";

export default () => {
  return (
    <Layout reference={CONSTANTS.PAGE_REFERENCES.HOME}>
      <Index />
    </Layout>
  );
};
